import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import { DebugHappeoThemeProvider } from "@happeouikit/theme";
import pkg from "../package.json";
import { TextMega, TextAlpha, TextBeta, TextGamma, TextDelta, TextEpsilon, BodyUI, TinyText, TextZeta } from '../src/index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <Playground __position={1} __code={'<TextMega>Text Mega regular</TextMega>\n<TextMega bold>Text Mega bold</TextMega>\n<TextAlpha>Text Alpha regular</TextAlpha>\n<TextAlpha bold>Text Alpha bold</TextAlpha>\n<TextBeta>Text Beta regular</TextBeta>\n<TextBeta bold>Text Beta bold</TextBeta>\n<TextGamma>Text Gamma regular</TextGamma>\n<TextGamma bold>Text Gamma bold</TextGamma>\n<TextDelta>Text Delta regular</TextDelta>\n<TextDelta bold>Text Delta bold</TextDelta>\n<TextEpsilon>Text Epsilon regular</TextEpsilon>\n<TextEpsilon bold>Text Espilon bold</TextEpsilon>\n<TextZeta>Text Zeta regular</TextZeta>\n<TextZeta bold>Text Zeta bold</TextZeta>\n<BodyUI>BodyUI regular</BodyUI>\n<BodyUI bold>BodyUI bold</BodyUI>\n<TinyText>Tiny Text regular</TinyText>\n<TinyText uppercase>Tiny Text regular uppercase</TinyText>\n<TinyText bold>Tiny Text bold</TinyText>\n<TinyText bold uppercase>\n  Tiny Text bold uppercase\n</TinyText>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PackageMetadata,
      DebugHappeoThemeProvider,
      pkg,
      TextMega,
      TextAlpha,
      TextBeta,
      TextGamma,
      TextDelta,
      TextEpsilon,
      BodyUI,
      TinyText,
      TextZeta,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <TextMega mdxType="TextMega">Text Mega regular</TextMega>
    <TextMega bold mdxType="TextMega">Text Mega bold</TextMega>
    <TextAlpha mdxType="TextAlpha">Text Alpha regular</TextAlpha>
    <TextAlpha bold mdxType="TextAlpha">Text Alpha bold</TextAlpha>
    <TextBeta mdxType="TextBeta">Text Beta regular</TextBeta>
    <TextBeta bold mdxType="TextBeta">Text Beta bold</TextBeta>
    <TextGamma mdxType="TextGamma">Text Gamma regular</TextGamma>
    <TextGamma bold mdxType="TextGamma">Text Gamma bold</TextGamma>
    <TextDelta mdxType="TextDelta">Text Delta regular</TextDelta>
    <TextDelta bold mdxType="TextDelta">Text Delta bold</TextDelta>
    <TextEpsilon mdxType="TextEpsilon">Text Epsilon regular</TextEpsilon>
    <TextEpsilon bold mdxType="TextEpsilon">Text Espilon bold</TextEpsilon>
    <TextZeta mdxType="TextZeta">Text Zeta regular</TextZeta>
    <TextZeta bold mdxType="TextZeta">Text Zeta bold</TextZeta>
    <BodyUI mdxType="BodyUI">BodyUI regular</BodyUI>
    <BodyUI bold mdxType="BodyUI">BodyUI bold</BodyUI>
    <TinyText mdxType="TinyText">Tiny Text regular</TinyText>
    <TinyText uppercase mdxType="TinyText">Tiny Text regular uppercase</TinyText>
    <TinyText bold mdxType="TinyText">Tiny Text bold</TinyText>
    <TinyText bold uppercase mdxType="TinyText">Tiny Text bold uppercase</TinyText>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      